import React from 'react';
import { BrowserRouter , Route, Routes, Navigate } from 'react-router-dom';
import Home from './Components/Pages/Home';
import About from './Components/Pages/About';
import Gallery from './Components/Pages/Gallery';
import CollectionView from './Components/Pages/CollectionView';
import Secret from './Components/Pages/Secret';

function App() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="gallery/:slug" element={<CollectionView />} />
          <Route path="secret" element={<Secret />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path='/admin' element={<Admin />}/>
        </Routes>
      </>
    </BrowserRouter>
  );
}

function Admin() {
  window.location.replace('https://twoltrip.sanity.studio/admin');

  return null;
}

export default App;