import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { client } from '../client';

function Banner(){
    const [bannerData, setBanner] = useState();

    useEffect(() =>{
        client.fetch(`*[_type in ["banner"]]{
            "bannerBg": bannerBg.asset->url,
            "bannerTitle": bannerTitle.asset->url,
            "bannerBorder": bannerBorder.asset->url,
            "bannerText": bannerText,
            "hueRotDuration": hueRotDuration
        }`)
        .then((data) =>{
            setBanner(data)
        })
        .catch(console.error);
    }, []);

    const hueRotator = {
        initial:{
            filter: "hue-rotate(0deg)"
        },
        animate: {
            filter: "hue-rotate(360deg)"
        }
    };

    return (
        bannerData ?
            <div 
                className="relative mb-8 md:mb-0 w-full md:w-[1000px] h-[200px] md:h-[560px] pointer-events-none select-none bg-none md:bg-contain"
                style={{backgroundImage: `url(${bannerData[0].bannerBg})`}}
            >
                <motion.img
                    src={bannerData[0].bannerBorder}
                    alt="banner_border"
                    className="w-full md:w-[1000px] h-[200px] md:h-[520px] absolute"
                    variants={hueRotator}
                    initial="initial"
                    animate="animate"
                    transition={{
                        duration: bannerData[0].hueRotDuration,
                        repeat: Infinity,
                        ease: "linear"
                    }}
                ></motion.img>
                <motion.img 
                    src={bannerData[0].bannerTitle} 
                    alt="banner_title" 
                    className="w-[350px] md:w-[650px] z-10 absolute top-5 md:top-16 mx-auto md:ml-16 left-0 right-0"
                    variants={hueRotator}
                    initial="initial"
                    animate="animate"
                    transition={{
                        duration: bannerData[0].hueRotDuration,
                        repeat: Infinity,
                        ease: "linear"
                    }}
                ></motion.img>
                <section className="w-full">
                    <div className="z-30 absolute top-[13rem] md:top-[23.5rem] text-center md:text-left text-2xl mx-auto md:ml-[5rem] left-0 right-0 ">
                        {bannerData[0].bannerText.map(text =><p
                            key={text._key} 
                            className=" text-white md:text-[#21262C]">
                                {text.children[0].text}
                            </p>)}
                    </div>
                    <div className="z-20 absolute top-[13rem] md:top-[23.5rem] text-center md:text-left text-2xl mx-auto md:ml-[5.2rem] left-0 right-0 ">
                        {bannerData[0].bannerText.map(text =><motion.p
                            key={text._key} 
                            className="hidden md:block bg-clip-text text-transparent bg-gradient-to-r from-[#41E3CB] to-[#41E36B]"
                            variants={hueRotator}
                            initial="initial"
                            animate="animate"
                            transition={{
                                duration: bannerData[0].hueRotDuration,
                                repeat: Infinity,
                                ease: "linear"
                            }}
                            >
                                {text.children[0].text}
                            </motion.p>)}
                    </div>
                    <div className="z-20 absolute top-[13rem] md:top-[23.5rem] text-center md:text-left text-2xl mx-auto md:ml-[4.8rem] left-0 right-0 ">
                        {bannerData[0].bannerText.map(text =><motion.p
                            key={text._key} 
                            className="hidden md:block bg-clip-text text-transparent bg-gradient-to-r from-[#FAA142] to-[#FA4242]"
                            variants={hueRotator}
                            initial="initial"
                            animate="animate"
                            transition={{
                                duration: bannerData[0].hueRotDuration,
                                repeat: Infinity,
                                ease: "linear"
                            }}
                            >
                                {text.children[0].text}
                            </motion.p>)}
                    </div>
                </section>
            </div>
        : "Loading..."
    )
};

export default Banner;