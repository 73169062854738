import React, { useState, useEffect } from 'react';
import NavButtonList from '../NavButtonList';
import { client } from '../../client';
import CollectionList from '../CollectionList';

function Gallery() {
    const [collectionData, setCollectionData] = useState();

    useEffect(() =>{
        client.fetch(`*[_type == "collection"]{
            name,
            year,
            order,
            slug,
            "coverImg": coverImg.asset->url,
            "blurHash": coverImg.asset->{metadata{blurHash}},
            pieces
        }`)
        .then((data) =>{
            setCollectionData(generateCollectionData(groupBy(data, "year")))
        })
        .catch(console.error);
    }, []);

    //Splits collections by year
    const groupBy = (arr, year) =>{
        return arr.reduce(function(item, x){
            if(!item[x[year]]){
                item[x[year]] = [];
            }
            item[x[year]].push(x);
            return item;
        }, {});
    }

    //Refactor content response into array
    const generateCollectionData = obj =>{
        let collectionArray = [];
        let collectionTitles = Object.keys(obj);

        collectionTitles.forEach(e =>{

            let tempObj = {
                year: e,
                content: obj[e]
            }

            collectionArray.push(tempObj);
        })

        return collectionArray;
    }
    
    return (
        <section className="selection:text-black selection:bg-[#00BFFF] md:mx-16 lg:mx-24 xl:mx-32 2xl:mx-64">
            <div className="mt-4 mx-4 flex flex-row justify-between">
                <NavButtonList homeOnly={2} size={{width: 100, height: 100}}/>
                <h1 className="pl-4 text-4xl text-white">Gallery</h1>
            </div>
            {collectionData ?
                <div className="flex flex-col mb-8 mt-4 text-4xl items-center md:items-start">
                    {collectionData.reverse().map(collection => <CollectionList
                        key={collection.year}
                        year={collection.year}
                        order={collection.order}
                        content={collection.content}
                        />)}
                </div>
            : 
            <div>No data</div>
            }
        </section>
    );
}

export default Gallery;