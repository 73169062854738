import { motion } from 'framer-motion';

function LinkButton(props) {
    return(
        <a href={props.link} target="_blank">
            <motion.img className="select-none" src={props.img} whileHover={{filter: "hue-rotate(180deg)"}} transition={{duration: 0}}></motion.img>
        </a>
    )
};

export default LinkButton;