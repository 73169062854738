import React, { useState, useEffect } from 'react';
import { client } from '../client';

function Footer() {
    const [footerData, setfooterData] = useState();

    useEffect(() =>{
        client.fetch(`*[_type == "footer"]{
            "footerText": footerText
        }`)
        .then((data) =>{
            setfooterData(data)
        })
        .catch(console.error);
    }, []);

    return (
        footerData ?
        <footer className="footer md:mb-10">
            <h1 className='text-white selection:text-black selection:bg-[#00BFFF]'>{footerData[0].footerText}</h1>
        </footer>
        : "Loading..."
    );
}

export default Footer;