import React from 'react';
import { useState, useEffect } from 'react';
import { client } from '../../client';
import NavButtonList from '../NavButtonList';
import { PortableText } from '@portabletext/react';
import ImageBlur from '../ImageBlur';
import { getImageUrl } from '@sanity/block-content-to-react';

function About() {
    const [aboutData, setAboutData] = useState();

    useEffect(() =>{
        client.fetch(`*[_type == "about"]{
            "aboutImage": aboutImage.asset->url,
            "blurHash": aboutImage.asset->{metadata{blurHash}},
            aboutText
        }`)
        .then((data) =>{
            setAboutData(data)
        })
        .catch(console.error);
    }, []);

    const components = {
        marks:{
            strong: ({children}) => <span className="font-bold">{children}</span>,
            em: ({children}) => <span className="italic">{children}</span>,
            link: ({value, children}) => <a className="text-[#00BFFF] underline" href={value?.href} target="_blank" rel="noreferrer">{children}</a>,
        },
        block: {
          h1: ({children}) => <h1 className="text-4xl mb-2">{children}</h1>,
          h2: ({children}) => <h2 className="text-2xl my-2">{children}</h2>,
        },
        list:{
            bullet: ({children}) => <ul className="list-disc">{children}</ul>
        },
        hardBreak: () => <br/>
      }

    if(!aboutData) return <div>Loading</div>;

    return (
        <section className="selection:text-black selection:bg-[#00BFFF] md:mx-16 lg:mx-24 xl:mx-32 2xl:mx-64">
            <div className="mt-4 mx-4 flex flex-row justify-between"> 
                <NavButtonList homeOnly={2} size={{width: 100, height: 100}}/>
                <h1 className="pl-4 text-4xl text-white">About</h1>
            </div>
            <div className="flex flex-col justify-center items-center my-8">
                <ImageBlur 
                    imageSource={aboutData[0].aboutImage}
                    imageAlt={"about_image"}
                    blurHash={aboutData[0].blurHash.metadata.blurHash}
                    blurSize={[512,512]}
                    imageStyle={"w-full lg:w-[512px] object-contain"}
                />
                <div className="text-white mt-8 text-xl text-center md:text-left mx-4 ">
                    <PortableText 
                        value={aboutData[0].aboutText}
                        components={components}
                    />
                </div>
            </div>

        </section>
    );
}

export default About;