import React from 'react';
import Banner from '../Banner';
import NavButtonList from '../NavButtonList';
import LinkButtonList from '../LinkButtonsList';
import Footer from '../Footer';

function Home() {

    return (
        <section className="flex flex-col items-center justify-center">
            <Banner/>
            <div className="mt-[10rem] mb-8 md:mt-0">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <NavButtonList navConfig={0}/>
                    <LinkButtonList />
                </div>
            </div>
            <Footer/>
        </section>
        
    );
}

export default Home;