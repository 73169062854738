import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import ImageBlur from './ImageBlur';

function CollectionItem(props) {
    
    return (
        <NavLink to={`/gallery/${props.slug}`}>
            <motion.div whileHover={{scale: 1.1}}>
                <ImageBlur 
                    imageSource={props.coverImg}
                    imageAlt={props.name}
                    blurHash={props.blurHash}
                    blurSize={[384,384]}
                    imageStyle={"w-[16rem] h-[16rem] md:w-96 md:h-96"}
                />
            </motion.div>
        </NavLink>
    );
}

export default CollectionItem;