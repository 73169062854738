import React, { useState, useEffect } from 'react';
import LinkButton from './LinkButton';
import { client } from '../client';

function LinkButtonList() {
    const [linkButtonData, setLinkButtons] = useState();

    useEffect(() =>{
        client.fetch(`*[_type == "linkButtons"]{
            name,
            linkUrl,
            "btnImage": btnImage.asset->url
          }`)
        .then((data) =>{
            setLinkButtons(data)
        })
        .catch(console.error);
    }, []);

    return(
        linkButtonData ?
        <>
        {linkButtonData.map(button =><LinkButton
            key={button.name}
            link={button.linkUrl}
            img={button.btnImage}
            />)}
        </>
        : "Loading..." 
    );
};

export default LinkButtonList;