import { motion } from 'framer-motion';

const transistion = {
    repeat: Infinity,
    duration: 0.8,
    ease: "linear"
};

const LoadingAnimation = () => {
    return (
        <motion.span 
            className="block w-[4rem] h-[4rem] border-[0.5rem] border-transparent border-t-[0.5rem] border-t-cyan-400 absolute box-border rounded-full"
            animate={{rotate: 360}}
            transition={transistion}
        />
    );
}

export default LoadingAnimation;