import React from 'react';
import CollectionItem from './CollectionItem';

function CollectionList(props) {
    console.log(props.content)
    
    return (
        <div className="flex flex-col">
            <h2 className="text-white py-2 md:py-8">{props.year}</h2>
            <div className="flex flex-col md:flex-row flex-wrap justify-start gap-4">
                {props.content.sort((a, b) => {return a.order - b.order}).map(e =><CollectionItem
                    key={e.name}
                    name={e.name}
                    blurHash={e.blurHash.metadata.blurHash}
                    coverImg={e.coverImg}
                    slug={e.slug.current}
                />)}
            </div>
        </div>
    );
}

export default CollectionList;