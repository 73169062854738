import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { client } from '../../client';
import NavButtonList from '../NavButtonList';
import PieceDisplay from '../PieceDisplay';

function CollectionView() {
    const [collectionData, setCollectionData] = useState();
    const { slug } = useParams();

    useEffect(() =>{
        client.fetch(`*[slug.current == "${slug}"]{
            name,
            year,
            slug,
            "pieces": pieces[].asset->url,
            "blurHash": pieces[].asset->{metadata{blurHash}},
            "title": pieces[].pieceTitle,
        }`)
        .then((data) =>{
            setCollectionData(data)
        })
        .catch(console.error);
    }, [slug]);

    if(!collectionData) return <div>Loading</div>;
    
    return (
        <section className="selection:text-black selection:bg-[#00BFFF] md:mx-16 lg:mx-24 xl:mx-32 2xl:mx-64">
            <div className="mt-4 mx-4 flex flex-col md:flex-row gap-4 justify-between">
                <div className="flex flex-row gap-4">
                    <NavButtonList navConfig={1} size={{width: 100, height: 100}}/>
                </div>
                <div className="flex flex-row justify-between mb-4 md:flex-col md:items-end">
                    <h1 className="text-2xl md:text-4xl text-white">{collectionData[0].name}</h1>
                    <h1 className="text-2xl md:text-4xl text-white">{collectionData[0].year}</h1>
                </div>
            </div>
            <section className="my-6 flex flex-col justify-center items-center">
                <PieceDisplay
                    current={collectionData[0]}
                />
            </section>
        </section>
    );
}

export default CollectionView;