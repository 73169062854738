import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function NavButton(props) {
    if(props.link === "/"){
        return <Link to={props.link}>
                    <motion.img className="select-none" style={props.size} src={props.img} whileHover={{filter: "hue-rotate(180deg)"}} transition={{duration: 0}}></motion.img>
                </Link>
    }
    return(
        <Link to={'/' +props.link}>
            <motion.img className="select-none" style={props.size} src={props.img} whileHover={{filter: "hue-rotate(180deg)"}} transition={{duration: 0}}></motion.img>
        </Link>
    )
};

export default NavButton;