import { motion } from 'framer-motion';

const container = {
    visible: {
        opacity: 0.7,
    },
    hidden: {
        opacity: 0,
    }
}

const piece = {
    visible: {
        opacity: 1

    },
    hidden: {
        opacity: 0
    }
}

function ZoomView(props) {

    return (
        <motion.div>
            <div className="absolute z-50 w-full h-10/12 top-0 left-0 flex items-center justify-center">
                <motion.img
                    className="w-full h-full cursor-zoom-out"
                    src={props.piece} 
                    onClick={props.toggleZoom}
                    variants={piece}
                    initial={"hidden"}
                    animate={"visible"}
                    exit={"hidden"}
                    transition={{ duration: 0.4}}
                />
            </div>
        <motion.div 
            className="fixed z-40 w-full h-full top-0 left-0 bg-black"
            variants={container}
            initial={"hidden"}
            animate={"visible"}
            exit={"hidden"}
            transition={{ duration: 0.4}}
        />
        </motion.div>
    );
}

export default ZoomView;