import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';

const ImageBlur = (props) => {
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
      const img = new Image();
      img.onload = () =>{
        setLoaded(true);
      }
      img.src = props.imageSource;
    }, [props.imageSource]);
    
    return (
        <>
        {!loaded &&(
            <Blurhash
                hash={props.blurHash}
                width={props.blurSize[0]}
                height={props.blurSize[1]}
                resolutionX={32}
                resolutionY={32}
                punch={1}
            />
        )}
        {loaded && (
            <img
                onLoad={props.loading}
                className={props.imageStyle}
                src={props.imageSource}
                alt={props.imageAlt}
                style={props.imageDisplay}
            />
        )}
        </>
    )
}

export default ImageBlur;
  