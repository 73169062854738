import React, { useState, useEffect, Fragment } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { client } from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { Blurhash } from 'react-blurhash';
import LoadingAnimation from './LoadingAnimation';
import Media from 'react-media'; 
import ZoomView from './ZoomView';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

function PieceDisplay(props) {
    const currentPieces = props.current;
    const [displayIndex, setDisplayIndex] = useState(0);
    const [displayTitle, setDisplayTitle] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [visible, setVisible] = useState(true);

    const imgSrc = urlFor(currentPieces.pieces[displayIndex]);
    
    useEffect(() => {
      const img = new Image();
      img.onload = () =>{
        setLoaded(true);
      }
      img.src = imgSrc;
    }, [imgSrc]);

    const handleSlideshow = button =>{
        switch(button){
            case 'back': 
                if(displayIndex === 0){
                    setLoaded(false);
                    return currentPieces.pieces.length-1;
                }else{
                    setLoaded(false);
                    return displayIndex-1;
                }
            case 'forward':
                if(displayIndex === currentPieces.pieces.length-1){
                    setLoaded(false);
                    return 0;
                }else{
                    setLoaded(false);
                    return displayIndex+1;
                }
            default:
                return 0;
        } 
    };

    const handleTitle = str =>{
        if(str.length > 55 && displayTitle === false){
            return `${str.substring(0, 52)}...`;
        }else{
            return str;
        }
    }

    const getImageDimensions = str  =>{
        let dimensions = str.split('-')[1].split('x');

        dimensions[1] = dimensions[1].split('.')[0];

        return dimensions;
    }

    const variants = {
        animate: {
            opacity: 0,
            transition: {
                duration: 4,
                ease: "linear"
            }
        },
        initial: { 
            opacity: 1
        }
    }

    return (
        <div className="flex flex-col items-center justify-center container">
            <div className="hidden sm:block mb-2">
                {!displayTitle &&(
                <h2 className=" text-white text-lg md:text-2xl lg:text-4xl" onMouseEnter={() =>{setDisplayTitle(true)}}>
                    {handleTitle(currentPieces.title[displayIndex])}
                </h2>
                )}
                {displayTitle &&(
                <h2  className=" text-white text-lg md:text-2xl lg:text-4xl" onMouseLeave={() =>{setDisplayTitle(false)}}>
                    {handleTitle(currentPieces.title[displayIndex])}
                </h2>
                )}             
            </div>
            <h2 className="sm:hidden px-4 text-white text-2xl">{currentPieces.title[displayIndex]}</h2>
            <div className="flex flex-row items-center justify-between w-full">
                <motion.button 
                    className="hidden sm:block h-[32rem] bg-[#00BFFF] px-4 text-4xl"
                    onClick={() =>{setDisplayIndex(handleSlideshow('back'))}}
                    whileHover={{backgroundColor: "#FF7737"}}
                    transition={{duration: 0}}
                
                >
                    {"<"}
                </motion.button>
                {!loaded &&(
                    <div className="fixed z-50 container flex items-center justify-center">
                        <LoadingAnimation />
                    </div>
                )}
                {!loaded &&(
                    <Media queries={{
                        small: '(max-width: 640px)',
                        medium: '(min-width: 641px)'
                    }}>
                        {matches => (
                            <Fragment>
                            {matches.small &&
                                <Blurhash
                                    hash={currentPieces.blurHash[displayIndex].metadata.blurHash}
                                    width={400}
                                    height={400}
                                    resolutionX={32}
                                    resolutionY={32}
                                    punch={1}
                                />}
                                {matches.medium &&
                                <Blurhash
                                    hash={currentPieces.blurHash[displayIndex].metadata.blurHash}
                                    width={1024}
                                    height={1024}
                                    resolutionX={32}
                                    resolutionY={32}
                                    punch={1}
                                />}
                            </Fragment>
                        )}
                    </Media>
                )}
                {loaded && (
                    <Media queries={{
                        small: '(max-width: 640px)',
                        medium: '(min-width: 641px)'
                    }}>
                        {matches => (
                            <Fragment>
                            {matches.small &&
                                <img
                                    className="w-full h-auto sm:w-[32rem] sm:h-[32rem] md:w-[42rem] md:h-[42rem] lg:w-[64rem] lg:h-[64rem] object-contain md:cursor-zoom-in"
                                    src={urlFor(currentPieces.pieces[displayIndex])}
                                    alt={currentPieces.title[displayIndex]}
                                />}
                             {matches.medium &&
                                <TransformWrapper
                                    initialScale={1}
                                    options={{ minScale: 1, maxScale: 1.5 }}
                                    wheel={{ step: 5, smoothStep: 0.01 }}
                                >
                                    <TransformComponent>
                                        <img 
                                            className="w-[32rem] h-[32rem] md:w-[42rem] md:h-[42rem] lg:w-[64rem] lg:h-[64rem] object-contain"
                                            src={urlFor(currentPieces.pieces[displayIndex])}
                                            alt={currentPieces.title[displayIndex]}
                                        />
                                    </TransformComponent>
                                </TransformWrapper>}
                            </Fragment>
                        )}
                    </Media>
                )}
                <motion.button 
                    className="hidden sm:block h-[32rem] bg-[#00BFFF] px-4 text-4xl"
                    onClick={() =>{setDisplayIndex(handleSlideshow('forward'))}}
                    whileHover={{backgroundColor: "#FF7737"}}
                    transition={{duration: 0}}
                >
                    {">"}
                </motion.button>
            </div>
            <div className="sm:hidden my-4 flex flex-row gap-4">
                <motion.button 
                    className="h-[3rem] w-[10rem] bg-[#00BFFF] px-3 text-4xl"
                    onClick={() =>{setDisplayIndex(handleSlideshow('back'))}}
                    whileHover={{backgroundColor: "#FF7737"}}
                    transition={{duration: 0}}
                    
                    >
                        {"<"}
                </motion.button>
                <motion.button 
                    className="h-[3rem] w-[10rem] bg-[#00BFFF] px-3 text-4xl"
                    onClick={() =>{setDisplayIndex(handleSlideshow('forward'))}}
                    whileHover={{backgroundColor: "#FF7737"}}
                    transition={{duration: 0}}
                >
                    {">"}
                </motion.button>
            </div>
            <Media queries={{
                        small: '(max-width: 640px)',
                        medium: '(min-width: 641px)'
            }}>
                {matches => (
                    <Fragment>
                    {matches.small &&
                        <div></div>}
                    {matches.medium &&
                        <motion.div 
                                className="flex flex-row items-center justify-center"
                                initial={true}
                                animate={visible ? "animate" : "initial"}
                                variants={variants}
                                >
                                <p className="text-[#00BFFF] text-2xl">scroll to zoom / drag to pan</p>
                        </motion.div >
                    }
                    </Fragment>
                )}
            </Media>
        </div>
    );
}

export default PieceDisplay;