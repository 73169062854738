import React, { useState, useEffect } from 'react';
import NavButton from './NavButton';
import { client } from '../client';

function NavButtonList(props) {
    const [navButtonData, setNavButtons] = useState();

    useEffect(() =>{
        client.fetch(`*[_type == "navButtons"]{
            name,
            navTo,
            "btnImage": btnImage.asset->url,
            order
        }`)
        .then((data) =>{
            if(props.navConfig === 0){
                setNavButtons(data.filter(btn => btn.name !== "Home"))
            }else if(props.navConfig === 1){
                setNavButtons(data.filter(btn => btn.name !== "About"))
            }else{
                setNavButtons(data.filter(btn => btn.name === "Home"))
            }
            
        })
        .catch(console.error);
    }, []);

    return(
        navButtonData ?
        <>
        {navButtonData.sort((a, b) => {return a.order - b.order}).map(button =><NavButton
            key={button.name}
            link={button.navTo}
            img={button.btnImage}
            size={props.size}
            />)}
        </>
        : "Loading..." 
    );
};

export default NavButtonList;